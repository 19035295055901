/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Image from "gatsby-image";
import "./index.css";

const ImageViewer2 = ({ onClose, allImages, currentPosition }) => {
  const [index, setIndex] = useState(currentPosition);
  if (typeof document === `undefined`) return null;

  function next() {
    for (let i = 0; i < allImages.length; i += 1) {
      if (
        allImages[index].childImageSharp.fluid.originalName ===
          allImages[i].childImageSharp.fluid.originalName &&
        i !== allImages.length - 1
      ) {
        setIndex(i + 1);
        break;
      }
    }
  }
  function previous() {
    for (let i = 0; i < allImages.length; i += 1) {
      if (
        allImages[index].childImageSharp.fluid.originalName ===
          allImages[i].childImageSharp.fluid.originalName &&
        i !== 0
      ) {
        setIndex(i - 1);
        break;
      }
    }
  }
  return (
    <div className="image-viewer-container">
      <button
        className="close-modal"
        onClick={() => {
          setIndex(currentPosition);
          onClose();
        }}
        type="button"
      >
        &times;
      </button>
      <div className="image-container-wrapper">
        <button
          type="button"
          className={index === 0 ? "image-previous arrow-disabled" : "image-previous"}
          onClick={previous}
        >
          &#8249;
        </button>
        <div
          onClick={() => {
            setIndex(currentPosition);
            onClose();
          }}
          role="presentation"
          className="image-modal"
        >
          {/* <Image fluid={allImages[index].childImageSharp.fluid} style={{ height: `100%` }} /> */}
          <Image fixed={allImages[index].childImageSharp.fixed} />
        </div>
        <button
          type="button"
          className={index === allImages.length - 1 ? "image-next arrow-disabled" : "image-next"}
          onClick={next}
        >
          &#8250;
        </button>
        <div className="image-buttons-mobile">
          <button
            type="button"
            className={
              index === 0 ? "image-previous-mobile arrow-disabled" : "image-previous-mobile"
            }
            onClick={previous}
          >
            &#8249;
          </button>
          <button
            type="button"
            className={
              index === allImages.length - 1
                ? "image-next-mobile arrow-disabled"
                : "image-next-mobile"
            }
            onClick={next}
          >
            &#8250;
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageViewer2;

