/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Layout from "components/Layout";
import SEO from "components/SEO";
import PhotographyItem from "components/PhotographyItem";
import ImageViewer2 from "components/ImageViewer2";
import { rhythm } from "utils/typography";
import "./litart.css";

function LitArt({ data, location }) {
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const imgs = data.allFile.nodes;

  const siteTitle = data.site.siteMetadata.title;
  return (
    <Layout
      location={location}
      title={siteTitle}
      fullWidth={true}
      breadcrumbConfig={{
        url: "/art",
        content: (
          <Link to="/art">
            <h1 style={{ margin: `0rem` }}>Lit Art</h1>
          </Link>
        )
      }}
    >
      <SEO title="Lit Art" description="Literature and photography, taken in context" />
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: rhythm(40),
          padding: `0`,
          marginBottom: `10px`
        }}
        className="expand-info"
      >
        Literature and photography, taken in context.
      </div>
      {!open && (
        <div className="row-photo">
          <div className="column-photo">
            {imgs.map((photo, i) => (
              <div
                key={i}
                onClick={() => {
                  setOpen(true);
                  setSelectedIndex(i);
                }}
                role="presentation"
              >
                <PhotographyItem photo={photo} allImages={data.allFile.nodes} index={i} />
              </div>
            ))}
          </div>
        </div>
      )}
      {open && (
        <ImageViewer2
          image={imgs[selectedIndex]}
          isOpen={open}
          onClose={() => {
            setOpen(false);
          }}
          allImages={imgs}
          currentPosition={selectedIndex}
        >
          {open}
        </ImageViewer2>
      )}
    </Layout>
  );
}

LitArt.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default LitArt;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allFile(
      filter: { relativePath: { regex: "/litart/" } }
      sort: { fields: modifiedTime, order: DESC }
    ) {
      nodes {
        childImageSharp {
          fluid {
            aspectRatio
            originalName
            ...GatsbyImageSharpFluid
          }
          fixed(width: 2000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`;

