import React from "react";
import Image from "gatsby-image";
import PropTypes from "prop-types";

const PhotographyItem = ({ photo }) => {
  return (
    <div>
      <Image
        fluid={photo.childImageSharp.fluid}
        style={{ width: `100%`, cursor: `zoom-in`, marginBottom: `10px` }}
      />
    </div>
  );
};
PhotographyItem.propTypes = {
  photo: PropTypes.any.isRequired
};
export default PhotographyItem;

